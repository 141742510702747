import { BaseService } from '@estee/elc-service';
import { ServiceInitializer } from '@estee/elc-universal-utils';

const { name, version } = __serviceInfo__;

export class ConfigManagerService extends BaseService {
    constructor(serviceInitializer: ServiceInitializer) {
        super(name, version, serviceInitializer);
    }
}

export default {
    configManagerApiSdk: 'CONFIG_MANAGER_API_SDK',
    httpTransport: 'HTTP_TRANSPORT',
    serviceBusController: 'SERVICE_BUS_CONTROLLER',
    configRepository: 'CONFIG_REPOSITORY',
    configManagerService: 'CONFIG_MANAGER_SERVICE'
};
