import { setupServiceInfo } from '@estee/elc-universal-utils';
import { ServiceManager } from '@estee/elc-service';
import serviceNames, { ConfigManagerService } from './service-setup/ConfigManagerService';
import { serviceBusBaseClass } from './service-setup/ServiceBus';

setupServiceInfo(__serviceInfo__);

const { name, version } = __serviceInfo__;

ConfigManagerService.setViews(name, name.replace(/-/g, '_'), version, []);

ServiceManager.setServiceStartParams({
    serviceName: name,
    diContainerImport: () => import('./service-setup/diContainer'),
    serviceKey: serviceNames.configManagerService,
    serviceBusControllerKey: serviceNames.serviceBusController,
    serviceBusBaseClass
});
