import { ServiceManager, ServiceBusController, ServiceBusControllerBody } from '@estee/elc-service';
import { events, on, queryProvider, serviceQueries } from '@estee/elc-service-bus';

const queries = [[serviceQueries.GET_CONFIG, 'loadConfigs']];

class ServiceBus extends ServiceBusController {
    @on(events.ECOMM_STARTED, { replay: true })
    public onEcommStarted = () => {
        queries.forEach(ServiceManager.setDecoratorOnFunction(queryProvider));
    };
}

export const serviceBusBaseClass = new ServiceBus() as ServiceBusControllerBody;
